<script setup lang="ts">
import type { NuxtError } from '#app'

useSeoMeta({
  title: 'Ops! Página não encontrada',
  description: 'Desculpe, a página que você está procurando não foi encontrada.',
})

defineProps({
  error: {
    type: Object as PropType<NuxtError>,
    required: true
  }
})

</script>

<template>
  <div>
    <AppPageTobBar />

    <AppMain>
      <BaseContainer>
        <AppPage>
          <AppPageError
            name="Página não encontrada"
            message="A página que você tentou acessar parece não existir."
            :clear-button="{
              label: 'Voltar pro início',
              to: '/'
            }"
          />
        </AppPage>
      </BaseContainer>
    </AppMain>

    <AppPageFooter />

    <UNotifications />
  </div>
</template>