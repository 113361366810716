import validate from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.14.1592_@types+node@20.17.7_eslint@9.15.0_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.14.1592_@types+node@20.17.7_eslint@9.15.0_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/home/runner/work/web-app/web-app/src/layers/auth/src/middleware/admin.ts"),
  auth: () => import("/home/runner/work/web-app/web-app/src/layers/auth/src/middleware/auth.ts"),
  guest: () => import("/home/runner/work/web-app/web-app/src/layers/auth/src/middleware/guest.ts"),
  leader: () => import("/home/runner/work/web-app/web-app/src/layers/auth/src/middleware/leader.ts")
}