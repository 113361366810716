// This Nuxt plugin performs the following actions:
// 1. Creates and mounts a component in the DOM to display a loading state.
// 2. Removes the loading component after the page loading is completed.
export default defineNuxtPlugin((nuxtApp) => {
  // Check if the 'document' object and its 'body' property exist.
  if (document && document.body) {
    // Create a 'div' element to display the loading component.
    const loaderHtmlComponent = document.createElement('div');
    // Insert the HTML code for the loading component, specified in the plugin options.
    loaderHtmlComponent.innerHTML = `<!-- https://github.com/barelyhuman/snips/blob/dev/pages/css-loader.md -->
<div class="loader"></div>
<style>
.loader {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #000;
  border-left-color: #000;
  border-bottom-color: #efefef;
  border-right-color: #efefef;
  border-radius: 50%;
  -webkit-animation: loader 400ms linear infinite;
  animation: loader 400ms linear infinite;
}

@keyframes loader {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
`;
    // Insert the loading component at the beginning of the document's body.
    document.body.insertBefore(loaderHtmlComponent, document.body.firstChild);

    function removeHTMLComponent() {
      if (loaderHtmlComponent) {
        // Remove the loading component.
        loaderHtmlComponent.remove();
      }
    }

    // Register the 'page:finish' hook to perform actions after page loading is complete.
    nuxtApp.hook('page:finish', () => {
      removeHTMLComponent();
    });

    // Remove element after fatal error occures
    nuxtApp.hook('app:error', () => {
      // Remove the loading component.
      removeHTMLComponent();
    });
  }
});