import routerOptions0 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.14.1592_@types+node@20.17.7_eslint@9.15.0_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui-pro@1.5.0_vite@5.4.11_vue@3.5.13/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}