<script setup lang="ts">
const { header } = useAppConfig();
</script>

<template>
  <AppHeader>
    <template #logo>
      <AppLogoText class="h-6" />
    </template>

    <template
      v-if="header?.links"
      #center
    >
      <UButton
        v-for="(link, index) of header.links"
        :key="index"
        v-bind="{ color: 'gray', variant: 'ghost', ...link }"
      />
    </template>

    <template
      v-if="header?.rightLinks"
      #right
    >
      <UButton
        v-for="(link, index) of header.rightLinks"
        :key="index"
        v-bind="{ color: 'gray', variant: 'ghost', ...link }"
      />
    </template>
  </AppHeader>
</template>