import payload_plugin_kM1y8rFTkz from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.3_firebase-admin@12.7.0_firebase-functions@4.9.0_f_autcmmid6jaapasy3e2gztlmym/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_oPmMljKsAl from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.14.1592_@types+node@20.17.7_eslint@9.15.0_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_SyGLcCkdk9 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.14.1592_@types+node@20.17.7_eslint@9.15.0_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_RjPCoApxD1 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.14.1592_@types+node@20.17.7_eslint@9.15.0_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_P2O4BSnzN5 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt-site-config@2.2.21_vite@5.4.11_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_9qnSTrTcv0 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.14.1592_@types+node@20.17.7_eslint@9.15.0_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_VYqEzGxUZ5 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.14.1592_@types+node@20.17.7_eslint@9.15.0_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_XNVT9Xj3sF from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.14.1592_@types+node@20.17.7_eslint@9.15.0_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_5UVtRtbjKh from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.14.1592_@types+node@20.17.7_eslint@9.15.0_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Pmk3P7Yzrs from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@pinia+nuxt@0.5.5_typescript@5.7.2_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import plugin_9jT3W5OS8r from "/home/runner/work/web-app/web-app/.nuxt/runtime/plugin.ts";
import components_plugin_KR1HBZs4kY from "/home/runner/work/web-app/web-app/.nuxt/components.plugin.mjs";
import prefetch_client_L58sWVSilW from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.14.1592_@types+node@20.17.7_eslint@9.15.0_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_mUgK1pb1VW from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui@2.19.2_vite@5.4.11_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_n0eKMtVqAZ from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui@2.19.2_vite@5.4.11_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_wtIiuaZWIZ from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui@2.19.2_vite@5.4.11_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_eNelkHx7DD from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_H9T0S0L2Xs from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+icon@1.8.2_vite@5.4.11_vue@3.5.13/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_kNLoP85aIp from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.3_firebase-admin@12.7.0_firebase-functions@4.9.0_f_autcmmid6jaapasy3e2gztlmym/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/home/runner/work/web-app/web-app/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/home/runner/work/web-app/web-app/.nuxt/vuefire-plugin.mjs";
import plugin_client_336Z7Bu9Cu from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.3_firebase-admin@12.7.0_firebase-functions@4.9.0_f_autcmmid6jaapasy3e2gztlmym/node_modules/nuxt-vuefire/dist/runtime/app-check/plugin.client.js";
import scrollbars_client_RKDsbGKcfT from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui-pro@1.5.0_vite@5.4.11_vue@3.5.13/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_i44jm5TPSD from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui-pro@1.5.0_vite@5.4.11_vue@3.5.13/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_HtoH38xU9u from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui-pro@1.5.0_vite@5.4.11_vue@3.5.13/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import maska_3yl0YyDJq9 from "/home/runner/work/web-app/web-app/src/layers/components/src/plugins/maska.ts";
import api_TPJcntO808 from "/home/runner/work/web-app/web-app/src/layers/commons/src/plugins/api.ts";
import remoteConfig_client_JXGjZ3KVCq from "/home/runner/work/web-app/web-app/src/layers/commons/src/plugins/remoteConfig.client.ts";
export default [
  payload_plugin_kM1y8rFTkz,
  revive_payload_client_oPmMljKsAl,
  unhead_SyGLcCkdk9,
  router_RjPCoApxD1,
  _0_siteConfig_P2O4BSnzN5,
  payload_client_9qnSTrTcv0,
  navigation_repaint_client_VYqEzGxUZ5,
  check_outdated_build_client_XNVT9Xj3sF,
  chunk_reload_client_5UVtRtbjKh,
  plugin_vue3_Pmk3P7Yzrs,
  plugin_9jT3W5OS8r,
  components_plugin_KR1HBZs4kY,
  prefetch_client_L58sWVSilW,
  slideovers_mUgK1pb1VW,
  modals_n0eKMtVqAZ,
  colors_wtIiuaZWIZ,
  plugin_client_eNelkHx7DD,
  plugin_H9T0S0L2Xs,
  plugin_client_kNLoP85aIp,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  plugin_client_336Z7Bu9Cu,
  scrollbars_client_RKDsbGKcfT,
  presets_i44jm5TPSD,
  variables_HtoH38xU9u,
  maska_3yl0YyDJq9,
  api_TPJcntO808,
  remoteConfig_client_JXGjZ3KVCq
]