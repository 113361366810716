export default defineAppConfig({
  ui: {
    primary: 'campus',
    gray: 'zinc',
    footer: {
      top: {
        wrapper: 'border-t border-gray-200 dark:border-gray-800',
        container: 'py-8 lg:py-16',
      },
      bottom: {
        wrapper: 'border-t border-gray-200 dark:border-gray-800',
      },
    },
    page: {
      body: {
        wrapper: 'mt-0 pb-24',
      },
    },
  },
});
