import { default as acesso_45negadoWYy00L4wNxMeta } from "/home/runner/work/web-app/web-app/src/layers/auth/src/pages/acesso-negado.vue?macro=true";
import { default as autenticacaoNPVNamw3NhMeta } from "/home/runner/work/web-app/web-app/src/layers/auth/src/pages/autenticacao.vue?macro=true";
import { default as indexrqgeXVyM8AMeta } from "/home/runner/work/web-app/web-app/src/layers/central/src/pages/central/conectar/index.vue?macro=true";
import { default as index3geoAMzbWAMeta } from "/home/runner/work/web-app/web-app/src/layers/central/src/pages/central/escalas/index.vue?macro=true";
import { default as indexnHANe8BcKEMeta } from "/home/runner/work/web-app/web-app/src/layers/central/src/pages/central/index.vue?macro=true";
import { default as indexGaiD94P1V1Meta } from "/home/runner/work/web-app/web-app/src/layers/central/src/pages/central/ministerios/index.vue?macro=true";
import { default as _91turma_93e0EbxQxCguMeta } from "/home/runner/work/web-app/web-app/src/layers/central/src/pages/central/voluntariar/[turma].vue?macro=true";
import { default as indexi4ax0uVX3oMeta } from "/home/runner/work/web-app/web-app/src/layers/central/src/pages/central/voluntarios/index.vue?macro=true";
import { default as indexKrFDKSo4aAMeta } from "/home/runner/work/web-app/web-app/src/layers/survey/src/pages/chat/index.vue?macro=true";
import { default as indexHjG2QDE7R1Meta } from "/home/runner/work/web-app/web-app/src/layers/central/src/pages/conectar/checkin/index.vue?macro=true";
import { default as indexFHWkRcGAKOMeta } from "/home/runner/work/web-app/web-app/src/pages/conectar/index.vue?macro=true";
import { default as inscricaobbbv5ZJH7QMeta } from "/home/runner/work/web-app/web-app/src/layers/central/src/pages/conectar/inscricao.vue?macro=true";
import { default as turmasV3X0RhilvLMeta } from "/home/runner/work/web-app/web-app/src/layers/central/src/pages/conectar/turmas.vue?macro=true";
import { default as entraroXRjM3B6aKMeta } from "/home/runner/work/web-app/web-app/src/layers/auth/src/pages/entrar.vue?macro=true";
import { default as indexaANckcH8JrMeta } from "/home/runner/work/web-app/web-app/src/pages/index.vue?macro=true";
import { default as levitas1oudxqKcq1Meta } from "/home/runner/work/web-app/web-app/src/pages/levitas.vue?macro=true";
import { default as _2024Fb6MlxO1hfMeta } from "/home/runner/work/web-app/web-app/src/layers/survey/src/pages/pesquisa/2024.vue?macro=true";
import { default as respostasFTmN4PQOM6Meta } from "/home/runner/work/web-app/web-app/src/layers/survey/src/pages/pesquisa/respostas.vue?macro=true";
import { default as politica_45privacidade4Q04hFoQPOMeta } from "/home/runner/work/web-app/web-app/src/pages/politica-privacidade.vue?macro=true";
import { default as sairnAvXqHiYIIMeta } from "/home/runner/work/web-app/web-app/src/layers/auth/src/pages/sair.vue?macro=true";
import { default as indexDRXErTWuQ7Meta } from "/home/runner/work/web-app/web-app/src/pages/sobre/index.vue?macro=true";
import { default as component_45stubtthCrzxuiqMeta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.14.1592_@types+node@20.17.7_eslint@9.15.0_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubtthCrzxuiq } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.14.1592_@types+node@20.17.7_eslint@9.15.0_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "acesso-negado",
    path: "/acesso-negado",
    component: () => import("/home/runner/work/web-app/web-app/src/layers/auth/src/pages/acesso-negado.vue")
  },
  {
    name: "autenticacao",
    path: "/autenticacao",
    meta: autenticacaoNPVNamw3NhMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/src/layers/auth/src/pages/autenticacao.vue")
  },
  {
    name: "central-conectar",
    path: "/central/conectar",
    meta: indexrqgeXVyM8AMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/src/layers/central/src/pages/central/conectar/index.vue")
  },
  {
    name: "central-escalas",
    path: "/central/escalas",
    meta: index3geoAMzbWAMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/src/layers/central/src/pages/central/escalas/index.vue")
  },
  {
    name: "central",
    path: "/central",
    meta: indexnHANe8BcKEMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/src/layers/central/src/pages/central/index.vue")
  },
  {
    name: "central-ministerios",
    path: "/central/ministerios",
    component: () => import("/home/runner/work/web-app/web-app/src/layers/central/src/pages/central/ministerios/index.vue")
  },
  {
    name: "central-voluntariar-turma",
    path: "/central/voluntariar/:turma()",
    meta: _91turma_93e0EbxQxCguMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/src/layers/central/src/pages/central/voluntariar/[turma].vue")
  },
  {
    name: "central-voluntarios",
    path: "/central/voluntarios",
    meta: indexi4ax0uVX3oMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/src/layers/central/src/pages/central/voluntarios/index.vue")
  },
  {
    name: "chat",
    path: "/chat",
    meta: indexKrFDKSo4aAMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/src/layers/survey/src/pages/chat/index.vue")
  },
  {
    name: "conectar-checkin",
    path: "/conectar/checkin",
    component: () => import("/home/runner/work/web-app/web-app/src/layers/central/src/pages/conectar/checkin/index.vue")
  },
  {
    name: "conectar",
    path: "/conectar",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/conectar/index.vue")
  },
  {
    name: "conectar-inscricao",
    path: "/conectar/inscricao",
    component: () => import("/home/runner/work/web-app/web-app/src/layers/central/src/pages/conectar/inscricao.vue")
  },
  {
    name: "conectar-turmas",
    path: "/conectar/turmas",
    component: () => import("/home/runner/work/web-app/web-app/src/layers/central/src/pages/conectar/turmas.vue")
  },
  {
    name: "entrar",
    path: "/entrar",
    meta: entraroXRjM3B6aKMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/src/layers/auth/src/pages/entrar.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/index.vue")
  },
  {
    name: "levitas",
    path: "/levitas",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/levitas.vue")
  },
  {
    name: "pesquisa-2024",
    path: "/pesquisa/2024",
    meta: _2024Fb6MlxO1hfMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/src/layers/survey/src/pages/pesquisa/2024.vue")
  },
  {
    name: "pesquisa-respostas",
    path: "/pesquisa/respostas",
    component: () => import("/home/runner/work/web-app/web-app/src/layers/survey/src/pages/pesquisa/respostas.vue")
  },
  {
    name: "politica-privacidade",
    path: "/politica-privacidade",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/politica-privacidade.vue")
  },
  {
    name: "sair",
    path: "/sair",
    component: () => import("/home/runner/work/web-app/web-app/src/layers/auth/src/pages/sair.vue")
  },
  {
    name: "sobre",
    path: "/sobre",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/sobre/index.vue")
  },
  {
    name: component_45stubtthCrzxuiqMeta?.name,
    path: "/15anos",
    component: component_45stubtthCrzxuiq
  }
]