<script setup lang="ts">
const user = useCurrentUser();
</script>
<template>
  <UFooter>
    <template #left>
      <p class="text-gray-500 dark:text-gray-400 text-sm">
        © {{ new Date().getFullYear() }} - Rua Belo Horizonte, 260, Adrianópolis, Manaus - AM
      </p>
    </template>

    <template #right>
      <BaseButton
        to="/sair"
        variant="ghost"
        v-if="user"
      >
        Sair
      </BaseButton>

      <AppColorModeButton size="sm" />

      <BaseButton
        to="https://instagram.com/ibcampusmanaus"
        target="_blank"
        icon="i-simple-icons-instagram"
        aria-label="Instagram"
        color="gray"
        variant="ghost"
      />
    </template>
  </UFooter>
</template>